import React from 'react';
import styled, { css } from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

type BaseMenuBirthdayPropsType = {
  className?: string;
};

const BaseMenuBirthday: React.FC<BaseMenuBirthdayPropsType> = ({ className }) => (
  <div className={className}>
    <div className="row">
      <div className="col-12">
        <StaticImage src="../images/birthday.jpg" alt="image" />
        <h2>
          <span>基本料金</span>
        </h2>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <th>対象</th>
              <th>基本料金(税込)</th>
            </tr>
            <tr>
              <td>男の子・女の子</td>
              <td>5,500円</td>
            </tr>
          </tbody>
        </table>
        <div className="spacer30"></div>
        <div className="card">
          <div className="card-body">
            <h5 className="caption-title">基本料金に含まれるもの</h5>
            <ul>
              <li>撮影時の衣装洋装２着分レンタル</li>
              <li>スタジオ写真撮影料</li>
              <li>衣装に合わせた着付け料、ヘアセット料</li>
            </ul>
            <div className="spacer30"></div>
            <ul className="">
              <li>消費税込の価格です</li>
              <li>撮影されるお子様１人の料金です。</li>
              <li>お子様ごとに基本料金がかかります</li>
              <li>この料金の他にプリント料金が別途かかります</li>
            </ul>
          </div>
        </div>
        <div className="spacer30"></div>
      </div>
    </div>
  </div>
);

const MenuBirthday = styled(BaseMenuBirthday)`
  ${() => {
    return css`
      & {
        .spacer30 {
          margin-bottom: 30px;
        }
        table thead th {
          text-align: center;
          width: 50%;
        }
        table tbody tr th,
        td {
          text-align: center;
          width: 50%;
        }
        ul,
        li {
          margin: 0;
        }
        .caption-title {
          font-weight: 700;
          text-align: center;
          margin-bottom: 30px;
        }
        .carousel-wrapper {
          margin: 30px;
        }
        h2 {
          position: relative;
          margin: 30px;
          text-align: center;
        }
        h2:before,
        h2:after {
          position: absolute;
          z-index: 0;
          bottom: -10px;
          display: block;
          content: '';
          border: 1em solid rgb(190, 36, 88);
        }
        h2:before {
          left: -30px;
          border-left-width: 15px;
          border-left-color: transparent;
        }
        h2:after {
          right: -30px;
          border-right-width: 15px;
          border-right-color: transparent;
        }
        h2 span {
          position: relative;
          z-index: 1;
          display: block;
          padding: 1rem 2rem;
          color: #fff;
          background: rgb(245, 90, 141);
        }
        h2 span:before,
        h2 span:after {
          position: absolute;
          bottom: -10px;
          display: block;
          width: 10px;
          height: 10px;
          content: '';
          border-style: solid;
          border-color: #b70505 transparent transparent transparent;
        }
        h2 span:before {
          left: 0;
          border-width: 10px 0 0 10px;
        }
        h2 span:after {
          right: 0;
          border-width: 10px 10px 0 0;
        }
      }
    `;
  }}
`;

export default React.memo(MenuBirthday);
